<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Países y ciudades'" />

    <!-- Locations tables -->
    <v-row class="my-8">
      <v-col cols="12" md="6">
        <v-card class="fill-height">
          <v-toolbar color="primary" dense>
            <span>Países</span>
          </v-toolbar>
          <v-list dense>
            <v-list-item-group v-model="selectedCountry" color="primary" :mandatory="selectedCountry != null ? true : false">
              <v-row class="py-2">
                <v-col v-for="(country, i) in countries" :key="i" cols="12" sm="6" class="py-0">
                  <v-list-item @change="getCities({ filter: { country: country.id } })">
                    <v-list-item-content>
                      <v-list-item-title>{{ country.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn
                        v-if="selectedCountry === i && ['SUPERADMIN'].includes(user.role)"
                        color="black"
                        x-small
                        icon
                        @click="(selectedCountryObject = { ...country }), (dialogEditCountry = true)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="fill-height">
          <v-toolbar color="primary" dense>
            <span>Ciudades</span>
          </v-toolbar>
          <v-list v-if="selectedCountry !== null" dense>
            <v-list-item-group color="primary">
              <v-row class="py-2">
                <v-col v-for="(city, i) in cities" :key="i" cols="12" sm="6" class="py-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ city.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list-item-group>
          </v-list>
          <v-card-text v-else>
            <p class="text-center mb-0">Selecciona un páis</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog edit country -->
    <DialogBox v-if="selectedCountryObject !== null" :model="dialogEditCountry">
      <template slot="toolbar">
        <span>
          Editar <b>{{ selectedCountryObject.name }}</b>
        </span>
      </template>
      <template slot="content">
        <v-form ref="formEditCountry" @submit.prevent="">
          <v-text-field
            v-model.number="selectedCountryObject.vat"
            type="number"
            label="IVA"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-text-field
            v-model.number="selectedCountryObject.arrear_vat"
            type="number"
            label="IVA por mora"
            suffix="%"
            :rules="formRules.numberRulesAllowZero"
          />
          <v-autocomplete
            v-model="selectedCountryObject.currency"
            :items="currencies"
            item-text="name"
            item-value="id"
            label="Moneda"
            :rules="[(v) => !!v || 'Campo obligatorio']"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogEditCountry', 'formEditCountry')">Cerrar</v-btn>
        <v-btn text @click="editCountry()">Guardar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Banner from "@/components/elements/Banner";
import DialogBox from "@/components/elements/DialogBox";
import FormRulesMixin from "@/mixins/FormRulesMixin";

export default {
  mixins: [FormRulesMixin],
  components: {
    Banner,
    DialogBox
  },
  data() {
    return {
      // Variables with position of list selected
      selectedCountry: null,

      // Variables with object of listselected
      selectedCountryObject: null,

      // Dialogs
      dialogEditCountry: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      countries: (state) => state.locations.countries,
      cities: (state) => state.locations.cities,
      currencies: (state) => state.currencies.currencies
    })
  },
  async created() {
    await this.getCountries();
    await this.$store.dispatch("currencies/getCurrencies");
  },
  methods: {
    ...mapActions({
      getCountries: "locations/getCountries",
      getCities: "locations/getCities"
    }),

    async editCountry() {
      if (this.$refs.formEditCountry.validate()) {
        const data = { ...this.selectedCountryObject };
        delete data.name;
        console.log(data);

        await this.$store.dispatch("locations/editCountry", data);
        await this.getCountries();
        this.$forceUpdate();

        this.closeDialog("dialogEditCountry", "formEditCountry");
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;
    }
  }
};
</script>
